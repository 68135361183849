import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { H3 } from "src/components/Shared/Text/Headers";
import useFetch from "src/hooks/useFetch";
import WorkspaceIntegrationsList from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationsList";
import DomainSection from "./DomainSection";

const GlobalSettings = ({ siteData }) => {
  const {
    response: { data: site },
    refreshData: refreshSite,
  } = useFetch("/site-settings/details");

  const [siteDomain, setSiteDomain] = useState("");
  const [loginPageTitle, setLoginPageTitle] = useState("");
  const [loginPageSubtitle, setLoginPageSubtitle] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [updatedChanges, setUpdateChanges] = useState(0);

  useEffect(() => {
    setSiteDomain(site?.site_domain);
    setLoginPageTitle(site?.login_page_title);
    setLoginPageSubtitle(site?.login_page_subtitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?._id]);

  const onSubmit = async () => {
    try {
      setIsDisabled(true);
      let body = {
        site_domain: siteDomain,
        login_page_title: loginPageTitle,
        login_page_subtitle: loginPageSubtitle,
      };
      await apiRequest("put", "/site-settings", { body });
      toast.success("General settings data successfully updated.");
      setUpdateChanges(0);

      refreshSite();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-y-4">
        <Section>
          <H3 caption="Setup general website items for the best user experience.">General Settings</H3>
          <div className="flex w-full flex-wrap gap-x-4 gap-y-6">
            <div className="w-full">
              <Input
                name="site-domain"
                label="User Access URL"
                value={siteDomain}
                inline={true}
                onChange={(e) => {
                  setIsDisabled(false);
                  setUpdateChanges((cur) => cur + 1);
                  setSiteDomain(e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                name="login-page-title"
                label="Login Page Title"
                value={loginPageTitle}
                inline={true}
                onChange={(e) => {
                  setIsDisabled(false);
                  setUpdateChanges((cur) => cur + 1);
                  setLoginPageTitle(e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <Input
                name="login-page-subtitle"
                label="Login Page Subtitle"
                value={loginPageSubtitle}
                inline={true}
                onChange={(e) => {
                  setIsDisabled(false);
                  setUpdateChanges((cur) => cur + 1);
                  setLoginPageSubtitle(e.target.value);
                }}
              />
            </div>
          </div>
          {updatedChanges > 0 && (
            <div className="mt-4 flex items-end justify-end gap-x-4 px-2">
              <div className="flex h-10 gap-x-2">
                <Button
                  version="gray"
                  disabled={isDisabled}
                  onClick={() => {
                    setSiteDomain(site.site_domain);
                    setLoginPageTitle(site.login_page_title);
                    setLoginPageSubtitle(site.login_page_subtitle);
                    setIsDisabled(true);
                    setUpdateChanges(0);
                  }}>
                  Undo
                </Button>
                <Button
                  disabled={isDisabled}
                  onClick={onSubmit}>
                  Update
                </Button>
              </div>
            </div>
          )}
        </Section>
        <Section>
          <div className="grid w-full gap-y-4">
            <WorkspaceIntegrationsList
              workspaceId={null}
              workspaceDetails={null}
              is_global={true}
            />
          </div>
        </Section>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, {})(GlobalSettings);
