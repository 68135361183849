import React from "react";
import { connect } from "react-redux";
import OptionTabs from "../Shared/Tabs/OptionTabs";
import ShareSmtpSettings from "./SmtpSettings/ShareSmtpSettings";
import OwnSmtpSettings from "./SmtpSettings/OwnSmtpSettings";
import Section from "../Shared/Containers/Section";
import { H3 } from "../Shared/Text/Headers";

const SmtpSettings = ({ workspaceId = null }) => {
  return (
    <Section className="space-y-4">
      <H3 caption="Adjust settings to allow for your clients to receive emails from your domain.">SMTP server settings</H3>
      <OptionTabs
        tabs={[
          {
            name: "I don't have a SMTP server",
            description: "This is typically the best option. Verify your domain (or a subdomain) and verifying an email and you can immediately start using our SMTP server.",
            component: <ShareSmtpSettings />,
          },
          {
            name: "Use my own SMTP server (Advanced)",
            description: "If you have your own SMTP server for sending emails, then select this option and enter in your credentials.",
            component: <OwnSmtpSettings workspaceId={workspaceId} />,
          },
        ]}
        activeTab={"I don't have a SMTP server"}
      />
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(SmtpSettings);
