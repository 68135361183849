import { ArrowPathIcon, Square2StackIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSharedSmtpSettings, getSharedSmtpDomainDetails, verifySharedSmtpDomain, manageSiteSettings } from "src/actions/site";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { H2, H3 } from "src/components/Shared/Text/Headers";

const DnsItem = ({ item, domainDetails }) => (
  <div className="flex border border-gray-200">
    <div className="flex w-36 flex-shrink-0 items-center px-4 py-4 text-sm text-gray-700">{item?.record_type}</div>
    <div className="flex w-1/2 max-w-[calc(50%-4.5rem)] flex-shrink items-center px-4 py-4">
      <div className="flex-shrink flex-grow whitespace-pre-wrap break-all text-sm text-gray-700">{item?.name || domainDetails?.name}</div>
      <CopyToClipboard
        text={item?.name || domainDetails?.name}
        onCopy={() => toast.success("Copied to clipboard")}>
        <Button
          type="button"
          version="default"
          className="cursor-pointer">
          <Square2StackIcon className="h-10 w-10 p-2 text-gray-500 hover:text-highlightColor" />
        </Button>
      </CopyToClipboard>
    </div>
    <div className="flex w-1/2 max-w-[calc(50%-4.5rem)] flex-shrink items-center px-4 py-4">
      <div className="flex-shrink flex-grow whitespace-pre-wrap break-all text-sm text-gray-700">{item?.value}</div>
      <CopyToClipboard
        text={item?.value}
        onCopy={() => toast.success("Copied to clipboard")}>
        <Button
          type="button"
          version="default"
          className="cursor-pointer">
          <Square2StackIcon className="h-10 w-10 p-2 text-gray-500 hover:text-highlightColor" />
        </Button>
      </CopyToClipboard>
    </div>
  </div>
);

const ShareSmtpSettings = ({ siteData, manageSharedSmtpSettings, manageSiteSettings, verifySharedSmtpDomain, workspaceId = null }) => {
  const [smtpDomain, setSmtpDomain] = useState(null);
  const [smtpDomainVerify, setSmtpDomainVerify] = useState(false);
  const [defaultSharedSmtp, setDefaultSharedSmtp] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDomainDetailsLoaded, setIsDomainDetailsLoaded] = useState(false);
  const [domainDetails, setDomainDetails] = useState(null);
  const [dnsList, setDnsList] = useState([]);

  const setDefaultValue = (data) => {
    setSmtpDomain(data?.smtp_domain || "");
    setSmtpDomainVerify(data?.smtp_domain_verify || false);
    setDefaultSharedSmtp(data?.default_shared_smtp || false);
  };

  const getWorkspaceSmtpData = async () => {
    // const data = await getWorkspaceSmtpSettings({ workspace_id: workspaceId });
    // getDefaultValue(data);
  };

  const onClear = () => {
    setDefaultValue(siteData);
    setIsDisabled(true);
  };

  const getDomainDetails = async () => {
    try {
      const data = await getSharedSmtpDomainDetails();
      setDomainDetails(data?.domain);
      setDnsList([...(data?.sending_dns_records || []), ...(data?.receiving_dns_records || [])]);
    } catch (error) {
    } finally {
      setIsDomainDetailsLoaded(true);
    }
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      setIsDisabled(true);

      let message;
      if (workspaceId) {
        // message = await manageWorkspaceSmtpSettings({ workspace_id: workspaceId, ...smtpData });
      } else {
        message = await manageSharedSmtpSettings({ smtp_domain: smtpDomain });
      }
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
      setIsDisabled(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onDefaultSharedSmtp = async () => {
    try {
      setIsLoading(true);
      setIsDisabled(true);

      let message;
      if (workspaceId) {
        // message = await manageWorkspaceSmtpSettings({ workspace_id: workspaceId, ...smtpData });
      } else {
        message = await manageSiteSettings({ default_shared_smtp: defaultSharedSmtp });
      }
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
      setIsDisabled(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onVerify = async () => {
    try {
      setIsLoading(true);

      let message;
      if (workspaceId) {
        // message = await manageWorkspaceSmtpSettings({ workspace_id: workspaceId, ...smtpData });
      } else {
        message = await verifySharedSmtpDomain({ smtp_domain_verify: smtpDomainVerify });
      }
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (workspaceId) {
      getWorkspaceSmtpData();
    } else {
      setDefaultValue(siteData);
      getDomainDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteData, workspaceId]);

  return (
    <Section>
      <div className="grid gap-y-8">
        {siteData?._id && isDomainDetailsLoaded ? (
          domainDetails ? (
            siteData?.smtp_domain_verify ? (
              <div>
                <H2>Set as default SMTP</H2>
                <div className="w-full">
                  <Checkbox
                    isChecked={defaultSharedSmtp}
                    onChange={(e) => {
                      setDefaultSharedSmtp(e.target.checked);
                      setIsDisabled(false);
                    }}
                    checkboxLabel={"Use this setup for default SMTP."}
                  />
                  <div className="ml-auto flex flex-shrink-0 gap-x-4 pt-2">
                    <Button
                      version="gray"
                      disabled={isDisabled}
                      onClick={onClear}>
                      Cancel
                    </Button>
                    <Button
                      disabled={isDisabled}
                      onClick={onDefaultSharedSmtp}
                      type="submit">
                      Save{isLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <H3 caption="You will need to install the following records to complete the process.">Install DNS Records</H3>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <div className="flex items-center gap-4">
                      <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border border-gray-300 text-sm font-semibold">1</div>
                      <div className="text-sm font-bold text-gray-600">Install and verify all DNS records listed below.</div>
                    </div>
                    <div className="w-full pl-12">
                      <div className="flex">
                        <div className="flex w-36 flex-shrink-0 items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Type</div>
                        <div className="flex w-1/2 flex-shrink items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Host</div>
                        <div className="flex w-1/2 flex-shrink items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Points To</div>
                      </div>
                      <div className="-space-y-px">
                        {dnsList?.length > 0 &&
                          dnsList?.map((item, index) => (
                            <DnsItem
                              key={index}
                              item={item}
                              domainDetails={domainDetails}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="flex items-center gap-4">
                      <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border border-gray-300 text-sm font-semibold">2</div>
                      <div className="text-sm font-bold text-gray-600">Confirm you've added these once completed.</div>
                    </div>
                    <div className="w-full pl-12">
                      <Checkbox
                        isChecked={smtpDomainVerify}
                        onChange={(e) => setSmtpDomainVerify(e.target.checked)}
                        checkboxLabel={"I've added these records."}
                      />
                      <div className="ml-auto flex flex-shrink-0 gap-x-4 pt-6">
                        <Button
                          disabled={!smtpDomainVerify || isLoading}
                          onClick={onVerify}
                          type="submit">
                          Verify{isLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <>
              <H3 caption="Enter your domain and edit any additional settings.">Authenticate your domain</H3>
              <div className="grid space-y-4 sm:space-y-8">
                <div className="flex w-full flex-wrap justify-between">
                  <div className="flex w-full flex-wrap gap-y-6">
                    <div className="flex w-full flex-col gap-3 sm:flex-row">
                      <div className="w-full sm:w-1/2">
                        <Input
                          label="Domain"
                          value={smtpDomain}
                          onChange={(e) => {
                            setSmtpDomain(e.target.value);
                            setIsDisabled(false);
                          }}
                          inline={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-auto flex flex-shrink-0 gap-x-4 pt-2">
                  <Button
                    version="gray"
                    disabled={isDisabled}
                    onClick={onClear}>
                    Cancel
                  </Button>
                  <Button
                    disabled={isDisabled}
                    onClick={onSubmit}
                    type="submit">
                    Save{isLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
                  </Button>
                </div>
              </div>
            </>
          )
        ) : (
          <Preloader />
        )}
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, { manageSharedSmtpSettings, manageSiteSettings, verifySharedSmtpDomain })(ShareSmtpSettings);
